/*navigation bar*/

.Home {
  border-radius: 50%;
  height:50px
}

ul.topnav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(37, 37, 37);
  border-radius: 5%;
}

ul.topnav li {float: left;}

ul.topnav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

ul.topnav li a:hover:not(.active) {background-color: rgba(159, 227, 12, 0.813);}

ul.topnav li a.active {background-color:rgba(159, 227, 12, 0.813);}

ul.topnav li.right {float: right;}

@media screen and (max-width: 600px) {
  ul.topnav li.right, 
  ul.topnav li {float: none;}
}

/*Home Page*/

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.column1 {
  flex: 50%;
  padding: 30px;
  
}

.column2 {
  flex: 50%;
  padding: 30px;
  line-height: 2;
  
}

.column3 {
  flex: 50%;
  padding: 30px;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other on smaller screens (600px wide or less) */
@media screen and (max-width: 800px) {
  .row {
    flex-direction: column;
  }
}

.Cart {
  width: 50px;
  height: 40px;
  border-radius: 25%;
}

.responsive {
  position: sticky;
  width: 100%;
  max-width: 500px;
  height: 100%;
  border-radius:7%;

}


*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}


/*menu page*/

.card-container{
  display:flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap:wrap;
  padding: 10px;

}

.card{
  width: 325px;
  height: auto;
  position: relative;
  background: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  border-radius: 10px;
  padding: 20px;
  transition: all .3s ease-in;
}

.card > * {
  padding: 10px;
}

.card .card-image{
  height: 150px;
  width: 150px;
  border: 5px double rgba(159, 227, 12, 0.813);
  border-radius: 10%;
  background-position: center center;
  background-size: cover;
}

.card .card-title{
  padding: 20px 10px;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-shadow: 1px 0px 2px black
}

.card:nth-child(1) .card-image{
  background-image: url(https://media.istockphoto.com/photos/steak-burrito-picture-id117179410?k=20&m=117179410&s=612x612&w=0&h=X80Q0NbaUt0BSaSBgc1yh94_2yAj7nepdV3mPrWg28g=)
}

.card:nth-child(2) .card-image{
  background-image: url(https://olo-images-live.imgix.net/a8/a820054844064dba80cd6e85fc6e7048.png?auto=format%2Ccompress&q=60&cs=tinysrgb&w=528&h=352&fit=fill&fm=png32&bg=transparent&s=7443a997ac21337b39e0fa64228d1850)
}

.card:nth-child(3) .card-image{
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM4yR2ZTuGYnWhAfTgvZfwii9or7FIuJr5kA&usqp=CAU)
}

.card:nth-child(4) .card-image{
  background-image: url(https://americanburrito.com/wp-content/uploads/2019/07/AmericanBurrito_097-1.jpg)
}


.card:hover{
  background:  rgba(204, 7, 7, 0.418);
  color: #fff;
  box-shadow: 0px 0px 11px 5px #000000;
}

.card .card-text {
  font-size: large;
  margin-bottom: 15px;
  font-style: bold;
}

button {
    position: bottom;
    top:50%;
    background-color:black;
    color: #fff;
    border:none;
    border-radius:10px;
    font-size:larger;
    
  }

  button:hover {
    background: whitesmoke;
    color: black;
    box-shadow: 0px 0px 11px 5px #000000;
  }

/* cart page*/ 

.cart-container{
  display:flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap:wrap;
  padding: 5px;

}

.cart{
  width: 800px;
  height: 100px;
  position: relative;
  background: gray;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items:left;
  border-radius: 10px;
  padding: 20px;
  transition: all .3s ease-in;
}

.cart > * {
  padding: 10px;
}

.cart .cart-image{
  height: 50px;
  width: 50px;
  border: 5px double rgba(159, 227, 12, 0.813);
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  align-items: left;
  margin-top: 6px;
}

.cart .cart-title{
  padding: 20px 10px;
  font-size: 1.0rem;
  text-transform: uppercase;
  text-shadow: 1px 0px 2px black;
  margin-left: 10px;
  margin-top: 5px;
  
}

.cart:nth-child(1) .cart-image{
  background-image: url(https://i.etsystatic.com/7867651/r/il/f8d868/1690141062/il_794xN.1690141062_lcp7.jpg)
}

.cart:nth-child(2) .cart-image{
  background-image: url(https://i.etsystatic.com/7867651/r/il/13937d/1737615009/il_794xN.1737615009_s23j.jpg)
}

.cart:nth-child(3) .cart-image{
  background-image: url(https://static.vecteezy.com/system/resources/thumbnails/005/246/725/small/kidney-beans-black-beans-in-cartoon-style-healthy-vegetarian-food-illustration-vector.jpg)
}

.cart:nth-child(4) .cart-image{
  background-image: url(https://i.etsystatic.com/7434544/r/il/90d48c/1706017439/il_794xN.1706017439_fg44.jpg)
}

.cart:nth-child(5) .cart-image{
  background-image: url(https://i.etsystatic.com/10919371/r/il/08e506/1529874052/il_1140xN.1529874052_1xc2.jpg)
}

.cart:nth-child(6) .cart-image{
  background-image: url(https://i.etsystatic.com/28388479/r/il/eef3ad/2968602690/il_794xN.2968602690_k793.jpg)
}


.cart:hover{
  background:  rgba(204, 7, 7, 0.418);
  color: #fff;
  box-shadow: 0px 0px 11px 5px #000000;
}

.cart .cart-text {
  font-size: 20px;
  margin-top:13px;
  font-style: bold;
}

.button2 {
  position: bottom;
  top:50%;
  background-color:black;
  color: #fff;
  border:none;
  border-radius:10px;
  font-size:larger;
  height:50px;
  width:50px ;
  margin-left: 100px;
}

.button2:hover {
  background: whitesmoke;
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.counter{
  font-size: 20px;
  margin-top: 13px;
}

.counter2{
  font-size: 20px;
  margin-top: 13px;
  margin-left:12px;
}

.button3 {
  top:50%;
  background-color:black;
  color: #fff;
  border:none;
  border-radius:10px;
  font-size:larger;
  height:35px;
  width:35px ;
  margin-top: 15px;
}

.button3:hover {
  background: whitesmoke;
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.button4 {
  background-color:black;
  color: #fff;
  border:none;
  border-radius:10px;
  font-size:larger;
  height:35px;
  width:35px ;
  margin-top: 15px;
}

.button4:hover {
  background: whitesmoke;
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.button5 {
  background-color:rgba(159, 227, 12, 0.813);
  color: black;
  border:none;
  border-radius:10px;
  font-size: 18px;
  height:35px;
  width:120px ;
  margin-top: 15px;
  margin-left: 10px;
}

.button5:hover {
  background: whitesmoke;
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.button6 {
  background-color:rgba(159, 227, 12, 0.813);
  color: black;
  border:none;
  border-radius:10px;
  font-size: 18px;
  height:35px;
  width:200px ;
  margin-top: 15px;
  margin-left: 10px;
}

.button6:hover {
  background: whitesmoke;
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.modal{
  display:flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap:wrap;
  padding: 5px;
  margin-top:40px;
}

.modal2{
  display:flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  gap: 5px;
  flex-wrap:wrap;
  padding: 5px;
  margin-top:-10px;
}

.modal-title{
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  text-shadow: 1px 0px 2px white;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 40px;
  
}


.modal-card{
  width: 800px;
  height: 800px;
  overflow:scroll;
  background: gray;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  border-radius: 10px;
  transition: all .3s ease-in;
}

.modal-card .cart-title{
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  text-shadow: 1px 0px 2px white;
  padding-bottom: 5px;
  padding-top: 5px;
  
}

.modal-card .cart-text {
  font-size: 15px;
  
  font-style: bold, italic;
}
.modal-card .cart-text2 {
  margin-top: 5px;
  font-size: 22px;
  font-style: bold, italic;
}







/*footer*/

.footer {
  padding-top: 0%;
  left: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 5px;

}

.header {
  padding-bottom: 12px;
  padding-top: 12px;
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  color: white;
  text-align: center;
  z-index: 999;
}
.header-image{
  height: 95px;
  width: 95px;
  border: white;
  border-radius: 5%;
  margin-bottom: 0;
  margin-top: 10px;
}
.login-img{
  height: 95px;
  width: 95px;
  border: 5px double rgba(159, 227, 12, 0.813);
  border-radius: 5%;
  
  margin-bottom: 20px;
  margin-top: 5px;
}

.button7 {
  background-color:black;
  color: white;
  border:none;
  border-radius:10px;
  font-size: 18px;
  height:35px;
  width:120px ;
  margin-top: 13px;
  margin-bottom: 13px;
  margin-left: 3px;
  margin-right: 3px;
}

.button7:hover {
  background: rgba(159, 227, 12, 0.813);
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.button8 {
  background-color:rgb(241, 46, 46);;
  color: white;
  border:none;
  border-radius:10px;
  font-size: 18px;
  height:35px;
  width:120px ;
  margin-top: 13px;
  margin-bottom: 13px;
}

.button8:hover {
  background: rgba(159, 227, 12, 0.813);
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}
.button9 {
  background-color:rgba(227, 48, 12, 0.813);
  color: white;
  border: 2px solid black;
  border-radius:10px;
  font-size: 18px;
  height:35px;
  width:200px ;
  margin-top: 15px;
  margin-left: 10px;
}

.button9:hover {
  background: whitesmoke;
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.button10 {
  background-color:rgba(159, 227, 12, 0.813);
  color: black;
  border:none;
  border-radius:10px;
  font-size: 18px;
  height:35px;
  width:200px ;
  margin-top: 10px;
  margin-left: 80px;
}

.button10:hover {
  background: whitesmoke;
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.button11 {
  background-color:rgba(159, 227, 12, 0.813);
  color: black;
  border:none;
  border-radius:10px;
  font-size: 18px;
  height:35px;
  width:200px ;
  margin-left: 10px;
}

.button11:hover {
  background: whitesmoke;
  color: black;
  box-shadow: 0px 0px 11px 5px #000000;
}

.input-email{
  width: 350px;
  height: 25px;
  background-color: white;
  font-size: 16px;
  margin-bottom: 3px;
  padding-left: 4px;
}

.input-email2{
  width: 350px;
  height: 25px;
  background-color: white;
  font-size: 16px;
  padding-left: 4px;
}
.input-name{
  width: 174px;
  height: 25px;
  background-color: white;
  font-size: 16px;
  margin-bottom: 3px;
  padding-left: 4px;
  margin-left: 1px;
}

.input-cc{
  width: 200px;
  height: 25px;
  background-color: white;
  font-size: 16px;
  margin-bottom: 3px;
  padding-left: 4px;
  
}

.input-cvv{
  width: 150px;
  height: 25px;
  background-color: white;
  font-size: 16px;
  margin-bottom: 3px;
  padding-left: 4px;
}
.input-city{
  width: 175px;
  height: 25px;
  background-color: white;
  font-size: 16px;
  margin-bottom: 3px;
  padding-left: 4px;
}
.input-state{
  width: 175px;
  height: 25px;
  background-color: white;
  font-size: 16px;
  margin-bottom: 3px;
  padding-left: 4px;
}
.input-zipcode{
  width: 100px;
  height: 25px;
  background-color: white;
  font-size: 16px;
  margin-bottom: 3px;
  padding-left: 4px;
}


.form{
  width: 350px;
  height: 250px;
  background: gray;
  flex-direction: column;
  align-items:center;
  transition: all .3s ease-in;
}

.label{
  font-size: 16px;
  padding-left: 15px;
}

.cclogo{
  height: 50px;
  width: 100px;
  border: whitesmoke;
  border-radius: 3%;
  padding-bottom: 10px;
  padding-top: 7px;
}
/*Complete*/

.complete-container{
  display:flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap:wrap;
  padding: 10px;

}

.complete-card{
  width: 400px;
  height: auto;
  position: relative;
  background: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  border-radius: 10px;
  padding: 20px;
  transition: all .3s ease-in;
}

.complete-card > * {
  padding: 10px;
}

.complete-card .complete-image{
  height: 300px;
  width: 300px;
  border: 5px double rgba(159, 227, 12, 0.813);
  border-radius: 25%;
  background-position: center center;
  background-size: cover;
}

.complete-card .complete-card-title{
  padding: 20px 10px;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-shadow: 1px 0px 2px black
}

.complete-card:nth-child(1) .complete-image{
  background-image: url(https://images.vice.com/munchies/wp_upload/burrito-man-2.jpg?resize=1000:*)
}




.complete-card:hover{
  background:  rgba(204, 7, 7, 0.418);
  color: #fff;
  box-shadow: 0px 0px 11px 5px #000000;
}

.complete-card .complete-card-text {
  font-size: large;
  margin-bottom: 15px;
  font-style: bold;
}

